//https://www.listen2myapp.com/account/users.assets/186/json/v6.6/app-setup.json
//https://api.allorigins.win/get?url=https://www.listen2myapp.com/account/users.assets/186/json/v6.6/app-setup.json

import axios from 'axios';
// import he from 'he';
import { configDict } from './config'

// App Setup Json

// memo function to store the the results in a map type, to avoid unnecessary axios requests
const memo = (callback) => {
  // We will save the key-value pairs in the following variable. It will be our cache storage
  const cache = new Map();
  return (...args) => {
    // The key will be used to identify the different arguments combination. Same arguments means same key
    const key = JSON.stringify(args);
    // If the cache storage has the key we are looking for, return the previously stored value
    if (cache.has(key)) return cache.get(key);
    // If the key is new, call the function (in your case axios.get)
    const value = callback(...args);
    //   console.log(...args)
    // And save the new key-value pair to the cache
    cache.set(key, value);
    return value;
  };
};


const memoizedAxiosGet = memo(axios.get);

function decodeResponse(str) {
  let res_to_string = decodeURI((JSON.stringify(str.data)).replace(/\+/g, ' '));

  let res = '';
  try {
    res = JSON.parse(res_to_string);
  } catch (e) {
    console.log(e); // error in the above string (in this case, yes)!
  }
  // let res = JSON.parse(res_to_string)
  return res;
}

const getData = async function (contentType, userId) {
  if (userId !== null && userId !== 0) {
    const JsonUrl = configDict["crosUrl"] + configDict["baseUrl"] + "/" + userId + "/json/" + configDict["versionId"] + "/" + configDict[contentType] + "?time=" + Math.floor(Date.now() / 600000);
    // console.log(JsonUrl)
    let res = await memoizedAxiosGet(JsonUrl)
    if (contentType === "appSetup")
      return decodeResponse(res)[0];
    else
      return decodeResponse(res);
  }
}

const getID = async function (name) {
  const url = "https://www.listen2myapp.com/account/get_user_with_radio_name.php?name=" + name
  let res = await memoizedAxiosGet(url)
  return res.data.id;
}

export { getData, getID }

