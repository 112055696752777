import { Box, Paper, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import React,{useEffect, useContext} from 'react';
import {getData} from '../config/utils'
import Skeleton from '@material-ui/lab/Skeleton';
import {Helmet} from "react-helmet";
import { ChannelContext } from '../context/channelContext'

export default function Links(props) {
    const [stateChannel,] = useContext(ChannelContext);
    const [links, setLinks] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
        if(stateChannel.radioID>0)
        getData("links",stateChannel.radioID).then(res => {
            setLinks(res)
            setLoading(false)
            })
    },[stateChannel.radioID])
    
  return (
    <Paper>
        <Helmet>
            <title>{stateChannel.radioName} - Links</title>
            <meta name="description" content="Links section" />
        </Helmet>
        <Box p={3} bgcolor="background.paper">
        {loading?
              (
                <div>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
              ) : (
                <List component="nav" aria-label="mailbox folders">
                    {links.map((elem,i)=>(
                        <React.Fragment key={i}>
                            <ListItem button component="a" href={elem.url} target="_blank" rel="noreferrer">
                                <ListItemText primary={elem.name} />
                            </ListItem>
                            {links.length !== i+1 ?<Divider />:""}
                        </React.Fragment>
                    ))}
                </List>
              )}
        </Box>
    </Paper>
  );
}