
import React, { useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { ChannelContext } from '../context/channelContext'
import { PlayerContext } from '../context/playerContext'

import https from 'https';

var parseString = require('xml2js').parseString;
const axios = require('axios');

const useStyles = makeStyles((theme) => ({
    content: {
      flex: '1 0 auto',
  }}));

const getStreamDetails = function(serverDetailsRes){
    let title = " ";
    let artist = " ";
    // console.log(serverDetailsRes)
    let serverDetails = serverDetailsRes;
    let type = serverDetails.stream_type;
        // console.log(type)
        if(type === "shoutcast1") {
            return new Promise(function (resolve, reject) {
                // console.log("https://api.allorigins.win/get?url=" + serverDetails.server_link + "/7.html")
                axios.get("https://api.allorigins.win/get?url=" + serverDetails.server_link + "/7.html" + "&time=" + Date.now())
                .then(response => {
                    try{
                        if(response.data.contents){
                            let res = response.data.contents;
                            res = res.replace('<html><body>','');
                            res = res.replace('</body></html>','');
                            res = res.replace("_", " ")
                            res = res.replace("\"", "'")
                            artist = ((res.split(",")[6]).split(" - "))[0]
                            title = ((res.split(",")[6]).split(" - "))[1]
                            resolve([title, artist])
                        }
                    } catch (error) {
                        resolve(["",""])
                        console.log(error)
                    }
                })
            });
        }
        else if(type === "shoutcast2") {
            return new Promise(function (resolve, reject) {
                // console.log("https://api.allorigins.win/get?url=" + serverDetails.server_link + "/currentsong?sid=" + serverDetails.streamid )
                axios.get("https://api.allorigins.win/get?url=" + serverDetails.server_link + "/currentsong?sid=" + serverDetails.streamid + "&time=" + Date.now())
                .then(response => {
                    try{
                        if(response.data.contents){
                            artist = ((response.data.contents+"").split(" - "))[0]
                            title = ((response.data.contents+"").split(" - "))[1]
                            resolve([title, artist])
                        }
                    }
                    catch (error) {
                        resolve(["",""])
                        console.log(error)
                    }
                })
            });
        }
        else if(type === "icecast2") {
            return new Promise(function (resolve, reject) {
            // console.log("https://api.allorigins.win/get?url=" + serverDetails.server_link + "/live" + ".xspf") //state.channels[state.server_id].mountpoint
            axios.get("https://api.allorigins.win/get?url=" + serverDetails.server_link + "/" +serverDetails.mountpoint + ".xspf" + "?time=" + Date.now())
            .then(response => {
                // console.log(response.data.contents)
                if(response.data.contents){
                    try{
                        parseString(response.data.contents, function (err, result) {
                            artist = ((result.playlist.trackList[0].track[0].title[0]+"").split(" - "))[0]
                            title = ((result.playlist.trackList[0].track[0].title[0]+"").split(" - "))[1]
                            resolve([title,artist])
                        });
                    }
                    catch (error) {
                        resolve(["",""])
                        console.log(error)
                    }
                }
            })
        });
    }
        else if(type === "samcloud") {
            return new Promise(function (resolve, reject) {
            axios({
                url: "https://listen.samcloud.com/webapi/station/" + serverDetails.streamid + "/history?token=" + serverDetails.token + "&top=1&format=json" + "&time=" + Date.now(),
                method: 'GET',
                headers: {
                'Content-Type': 'application/json'
                },
                responseType: 'json',
                httpsAgent: new https.Agent({ rejectUnauthorized: false })
                })
            .then(response => {
                if(response.data[0]){
                    title = response.data[0].Title
                    artist = response.data[0].Artist
                    resolve([title, artist])
                }
            })
        });
    }
    else if (type === "radiojar"){
        return new Promise(function (resolve, reject) {
            // console.log("https://now-dot-playing-dot-radiojarcom.appspot.com/api/stations/" + (serverDetails.server_link).slice(27) + "/now_playing/" + "?time=" + Date.now())
            axios.get("https://api.allorigins.win/get?url=" + "https://now-dot-playing-dot-radiojarcom.appspot.com/api/stations/" + (serverDetails.server_link).slice(27) + "/now_playing/" + "?time=" + Date.now())
            .then(response => {
                if(response.data){
                    title = JSON.parse(response.data.contents).title
                    artist = JSON.parse(response.data.contents).artist
                    resolve([title, artist])
                }
            })
        });
    }
    else if (type === "live365"){
        return new Promise(function (resolve, reject) {
            // console.log("https://api.live365.com/station/" + serverDetails.account_id + "?time=" + Date.now())
            axios.get("https://api.live365.com/station/" + serverDetails.account_id + "?time=" + Date.now())
            .then(response => {
                // console.log(response.data['current-track'])
                if(response.data['current-track']){
                    title = response.data['current-track'].title
                    artist = response.data['current-track'].artist
                    resolve([title, artist])
                }
            })
        });
    }
    else if( type === "radio.co"){
        return new Promise(resolve => {
            let metadataUrl = serverDetails.server_link;

            metadataUrl = metadataUrl.replace("http://streaming", "https://public");
            metadataUrl = metadataUrl.replace("http://streamer", "https://public");
            metadataUrl = metadataUrl.replace("http://stream", "https://public");
            //New entry
            metadataUrl = metadataUrl.replace("https://streamer", "https://public");

            //New entry 2020 Dec
            metadataUrl = metadataUrl.replace("http://stream", "https://public");
            metadataUrl = metadataUrl.replace("https://stream", "https://public");

            //Another 2 new entry 2019 19 sep
            metadataUrl = metadataUrl.replace("http://s2", "https://public");
            metadataUrl = metadataUrl.replace("https://s2", "https://public");

            //Another 2 new entry 2019 19 sep
            metadataUrl = metadataUrl.replace("http://s3", "https://public");
            metadataUrl = metadataUrl.replace("https://s3", "https://public");

            //Another 2 new entry 2020 25 Aug
            metadataUrl = metadataUrl.replace("http://s4", "https://public");
            metadataUrl = metadataUrl.replace("https://s4", "https://public");

            metadataUrl = metadataUrl.replace("radio.co/", "radio.co/stations/");
            metadataUrl = metadataUrl.replace("low", "status");
            metadataUrl = metadataUrl.replace("listen", "status");

            axios.get(metadataUrl + "?time=" + Date.now())
            .then(response => {
                if(response.data['current_track'].title){
                    try{
                            artist = ((response.data['current_track'].title+"").split(" - "))[0]
                            title = ((response.data['current_track'].title+"").split(" - "))[1]
                            resolve([title,artist])
                    }
                    catch (error) {
                        resolve(["",""])
                        console.log(error)
                    }
                }
            })
        })
    }
    else if (type === "podcast" || type === "mp3" || type === "other"){
        return new Promise(function (resolve, reject) {
                // console.log(serverDetails.title + " " + serverDetails.subtitle)
                    title = serverDetails.title
                    artist = serverDetails.subtitle
                    resolve([title, artist])
        });
    }
        else {
            return new Promise(resolve => {
                resolve(["", ""])
            })
        }
}


export default function PlayerTitle() {
    const [stateChannel, ] = useContext(ChannelContext);
    const [statePlayer, ] = useContext(PlayerContext);
    const [title, setTitle] = useState("")
    const [artist, setArtist] = useState("")

    const classes = useStyles();
    let serverDetails = stateChannel.channels[statePlayer.server_id];

    useEffect(() => {
        if(stateChannel.channels[statePlayer.server_id]){
                const interval = setInterval(() => {
                    getStreamDetails(serverDetails).then((result)=>{
                            // console.log(result)
                            if(result){
                                setTitle(result[0])
                                setArtist(result[1])
                            }
                        })
                }, 60000); //every 60 sec = 60000millisec
                getStreamDetails(serverDetails).then((result)=>{
                    if(result){
                        // console.log(result)
                        setTitle(result[0])
                        setArtist(result[1])
                    }
                })
                return () => clearInterval(interval);
        }
    
      },[statePlayer.server_id,stateChannel.channels]);

    return (
        <CardContent className={classes.content}>
        <Typography component="h5" variant="h5">
          {title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {artist}
        </Typography>
      </CardContent>
    )}