import { Box, Paper, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import React,{ useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { ChannelContext } from '../context/channelContext'

import {getData} from '../config/utils'
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
    height: "100%",
  },
}));
  
let imgHeights = {};
let imgWidths = {};

function addSizes(images){
  images.forEach(imgUrl => {
    let img = new Image();
    img.src = imgUrl.image_thumb_150;
    img.title = imgUrl.title;
    img.id = imgUrl.image_id;
    // console.log(img.height)
    img.onload = () => {
      imgHeights[imgUrl.image_id] = img.height;
      imgWidths[imgUrl.image_id] = img.width;
    }
  })
  // console.log(imgHeights)
  // console.log(imgWidths)
}

export default function Gallery(props) {
    const [stateChannel,] = useContext(ChannelContext);
    const classes = useStyles();
    const [gallery, setGallery] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [loadingList, setLoadingList] = React.useState(true)

    let gallery_id = props.match.params.gallery_id;
    const matches = useMediaQuery('(min-width:600px)');
    
    useEffect(async () => {
      if(stateChannel.radioID>0)
      getData("gallery",stateChannel.radioID).then(res => {
          if(gallery_id) addSizes(res[gallery_id-1].images)
          setGallery(res)
          setLoading(false)
          setLoadingList(false)
          })
    },[stateChannel.radioID,gallery_id])   

  return (
    <Paper>
      <Helmet>
        <title>{stateChannel.radioName} - Gallery</title>
        <meta name="description" content="Gallery section" />
      </Helmet>
      { gallery_id >=0?(
        <Box p={3} bgcolor="background.paper">
        {loading?
              (
                <div>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
              ) : (
                  <div className={classes.root}>
                  <GridList cellHeight={200} className={classes.gridList} cols={6}>
                    {gallery[gallery_id-1].images.map((img) => (
                      <GridListTile 
                          key={img.image_id} 
                          rows={matches?(imgHeights[img.image_id]>imgWidths[img.image_id]?4:2):1} 
                          cols={matches?(imgHeights[img.image_id]>imgWidths[img.image_id]?2:2):6}
                      >
                        <img src={img.image_thumb_150} alt={img.title} />
                      </GridListTile>

                    ))}
                  </GridList>
                </div>)
          }
        </Box>
      ):(
        <Box p={3} bgcolor="background.paper">
            <List component="nav" aria-label="mailbox folders">
            {loadingList?
              (
                <div>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </div>
              ):(
                gallery.map((elem,i)=>(
                    <React.Fragment key={i}>
                        <ListItem button component={Link} to={"Gallery/" + elem.gallery_id}>
                            <ListItemText primary={elem.title} />
                        </ListItem>
                        {gallery.length !== i+1 ?<Divider />:""}
                    </React.Fragment>
                ))
              )}
            </List>
        </Box>
        )}
    </Paper>
  );
}