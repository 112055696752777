import React, { useContext, useState} from 'react';
import { Box, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ChannelContext } from '../context/channelContext'
import { Helmet } from 'react-helmet';

const axios = require('axios');

const validationSchema = yup.object({
    name: yup
    .string('Enter your name')
    .required('Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    message: yup
      .string('Enter your message')
      .required('Message is required'),
  });

export default function Social(props) {
    const [stateChannel,] = useContext(ChannelContext);
    const [successfullySent, setSuccessfullySent] = useState(false)
    const [errorDuringSend, setErrorDuringSend] = useState(false)
    // let id = props.match.params.id;
    var optionAxios = {
        headers: {
            'Content-Type': 'multipart/form-data'
        } 
    }
      const formik = useFormik({
        initialValues: {
          name: '',
          email: '',
          message: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            var bodyFormData = new FormData();
            bodyFormData.append('name', values.name);
            bodyFormData.append('listener_email', values.email);
            bodyFormData.append('message', values.message);
            bodyFormData.append('user_id', stateChannel.radioID);

            axios.post('https://www.listen2myapp.com/account/process-inbox.php',bodyFormData,optionAxios)
              .then(function (response) {
                // console.log(response.data);
                // console.log(response.status);
                // console.log(response.statusText);
                // console.log(response.headers);
                // console.log(response.config);
                if(response.statusText === 'OK') setSuccessfullySent(true)
                else setErrorDuringSend(true)
              })
              .catch(function (error) {
                console.log(error);
              });
        //   alert(JSON.stringify(values, null, 2));
        },
      });

  return (
    <Paper>
      <Helmet>
        <title>{stateChannel.radioName} - Contact Form</title>
        <meta name="description" content="Contact form section" />
      </Helmet>
        <Box p={3} bgcolor="background.paper">
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <br/><br/>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Email Address"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <br/><br/>
                    <TextField
                      fullWidth
                      multiline
                      id="message"
                      name="message"
                      label="Message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      error={formik.touched.message && Boolean(formik.errors.message)}
                      helperText={formik.touched.message && formik.errors.message}
                    />
                    <br/><br/>
                    {!successfullySent && 
                        <Button variant="outlined" type="submit" color="primary">
                            Send
                        </Button>
                    }
                    {successfullySent && <h3>Thanks, the message has been sent</h3>}
                    {errorDuringSend && <h3>This message can't be sent right now. Please try again later</h3>}
                </form>
            </div>
        </Box>
    </Paper>
  );
}