import React,{useEffect} from 'react';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
    contactTitle: {
        maxWidth: '160px',
    }
}));

export default function LastSongs(props) {
    const [songsList, setSongsList] = React.useState("")
    const [loading, setLoading] = React.useState(true)

    const classes = useStyles(props);

    let id = props.match.params.id;
    
    useEffect(() => {
      axios().then(res => {
        setSongsList(res)
        setLoading(false)
            })
        },[id])
    
  return (
    <Paper>
        <Box p={3} bgcolor="background.paper">
            songs list
        </Box>
    </Paper>
  );
}