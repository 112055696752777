import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { getData } from '../config/utils'
import { iconsDict } from '../config/icons'
import { sectionsDeveloped } from '../config/config'

import { ChannelContext } from '../context/channelContext'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));

export default function GetMenuSetupFull(props) {
    const [stateChannel, ] = useContext(ChannelContext);
    const classes = useStyles();

    // const res = getData("appSetup",props.radioId);
    // filter "location" params and create a list of jsx menu items
    const [selectedIndex, setSelectedIndex] = useState();
    const [menuItems, setMenuItems] = useState([])

    useEffect(() => {
    setMenuItems([])
    getData("appSetup",stateChannel.radioID).then(res => {
      // console.log(res)

        for(var item in res){
            if(item.startsWith("location")) {
              if(!res[item].startsWith("custom_tab")){
                sectionsDeveloped[res[item]] && setMenuItems(menuItems => 
                    [...menuItems, 
                        <ListItem 
                        key={res[item]}
                        button 
                        component={Link}
                        selected={res[item].replace(/ /g,"") == window.location.href.split('/')[4]}
                        to={"/" + props.name + "/" + res[item].replace(/ /g,"")}
                        onClick={(event)=>setSelectedIndex(Math.random())}
                      >
                        <ListItemIcon>
                          {iconsDict[res[item]]}
                        </ListItemIcon>
                        <ListItemText primary={res[item]} />
                      </ListItem>
                    ]
                )
              } else {
                false && setMenuItems( menuItems => 
                    [...menuItems, 
                        <ListItem 
                            key={res[item]} 
                            button
                            // onClick={(e) => (window.open("https://www.google.com", "_blank"))} 
                            target="_blank" 
                            selected={res[res[item]].title == window.location.href.split('/')[4]}
                            onClick={(event)=>setSelectedIndex(Math.random())}
                        >
                            <ListItemIcon>
                                {iconsDict[res[res[item]].icon.replace(/_/g, ' ')]} {/* some icons written with underscore, replaces it back to space */}
                            </ListItemIcon>
                            <ListItemText primary={res[res[item]].title} />
                        </ListItem>
                    ])
              }
            }
          }
        })
    },[selectedIndex,stateChannel.radioID])
  
    return (
        <div className={classes.root}>
            <List component="nav">
                <ListItem 
                    button
                    selected={'' === window.location.href.split('/')[4] || !window.location.href.split('/')[4]}
                    component={Link} 
                    to={"/" + props.name + "/"}
                    onClick={(event)=> {
                        setSelectedIndex('')
                    }}
                >
                <ListItemIcon>
                    {iconsDict["Media Player"]}
                </ListItemIcon>
                <ListItemText primary="Media Player" />
                </ListItem>
                {  menuItems } {/*list of menu items*/}
            </List>
        </div>
      );
  }