import React,{useEffect, useState, useContext} from 'react';
import { Box, Button, Paper, Divider, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {getData} from '../config/utils';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import SmsIcon from '@material-ui/icons/Sms';
import LanguageIcon from '@material-ui/icons/Language';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import Skeleton from '@material-ui/lab/Skeleton';
import { ChannelContext } from '../context/channelContext'

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    contactTitle: {
        maxWidth: '160px',
    }
}));

export default function Contact(props) {
  const [stateChannel,] = useContext(ChannelContext);
  const [contact, setContact] = useState({})
  const [loading, setLoading] = useState(true)

  const classes = useStyles(props);
  
  useEffect(() => {
    if(stateChannel.radioID>0)
    getData("contact",stateChannel.radioID).then(res => {
      // console.log(res)
      setContact(res)
      setLoading(false)
    })
  },[stateChannel.radioID])
    
  return (
    <Paper>
        <Helmet>
            <title>{stateChannel.radioName} - Contact</title>
            <meta name="description" content="Contact section" />
        </Helmet>
        <Box p={3} bgcolor="background.paper">

            {contact["inbox-tab"] === "Show"?(
                <Button variant="outlined" component={Link} to="ContactForm" color="primary">
                    Send Message
                </Button>
            ):""}
          {loading?
              (
                <div>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
              ) : (
            <List component="nav" aria-label="mailbox folders">
              {contact.phone!=="" && contact.phone?(
                <ListItem button component="a" href={"tel:" + contact.phone} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.contactTitle} primary={"Phone Number:"} />
                  <ListItemText primary={contact.phone} />
                </ListItem>
              ):""}
              {contact.phone!==""&&(contact.email ||contact.sms_number ||contact.website ||contact.whatsapp_number ||contact.telegram_username ||contact.real_address ||contact.extra_details)?( <Divider />):""}
              {/* {contact.phone!==""&&(contact.email!==""||contact.sms_number!==""||contact.website!==""||contact.whatsapp_number!==""||contact.telegram_username!==""||contact.real_address!==""||contact.extra_details!=="")?( <Divider />):""} */}

              {contact.email!=="" && contact.email?(
                <ListItem button component="a" href={"mailto:" + contact.email} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <AlternateEmailIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.contactTitle} primary={"Email Address:"} />
                  <ListItemText primary={contact.email} />
                </ListItem>
              ):""}
              {/* {contact.email!==""&&(contact.sms_number!==""||contact.website!==""||contact.whatsapp_number!==""||contact.telegram_username!==""||contact.real_address!==""||contact.extra_details!=="")?( <Divider />):""} */}
              {contact.email!==""&&(contact.sms_number ||contact.website ||contact.whatsapp_number ||contact.telegram_username ||contact.real_address ||contact.extra_details)?( <Divider />):""}

              {contact.sms_number!=="" && contact.sms_number?(  
                <ListItem button component="a" href={"sms:" + contact.sms_number} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <SmsIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.contactTitle} primary={"SMS Number:"} />
                  <ListItemText primary={contact.sms_number} />
                </ListItem>
              ):""}
              {/* {contact.sms_number!==""&&(contact.website!==""||contact.whatsapp_number!==""||contact.telegram_username!==""||contact.real_address!==""||contact.extra_details!=="")?( <Divider />):""} */}
              {contact.sms_number!==""&&(contact.website ||contact.whatsapp_number ||contact.telegram_username ||contact.real_address ||contact.extra_details)?( <Divider />):""}

              {contact.website!=="" && contact.website?(  
                <ListItem button component="a" href={contact.website} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <LanguageIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.contactTitle} primary={"Website:"} />
                  <ListItemText primary={contact.website} />
                </ListItem>
              ):""}
              {/* {contact.website!==""&&((contact.whatsapp_number!=="" && contact.whatsapp_number) || (contact.telegram_username!=="" && contact.telegram_username) || (contact.real_address!=="" && contact.real_address)|| (contact.extra_details!=="" && contact.extra_details))?( <Divider />):""} */}
              {contact.website!==""&&(contact.whatsapp_number ||contact.telegram_username ||contact.real_address ||contact.extra_details)?( <Divider />):""}

              {contact.whatsapp_number!=="" && contact.whatsapp_number ?(  
                <ListItem button component="a" href={contact.whatsapp_number} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <WhatsAppIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.contactTitle} primary={"Whatsapp Number:"} />
                  <ListItemText primary={"+" + contact.whatsapp_number.slice(36)} />
                </ListItem>
              ):""}
              {/* {contact.whatsapp_number!==""&&((contact.telegram_username!=="" && contact.telegram_username) || (contact.real_address!=="" && contact.real_address)|| (contact.extra_details!=="" && contact.extra_details))?( <Divider />):""} */}
              {contact.whatsapp_number!==""&&(contact.telegram_username ||contact.real_address ||contact.extra_details)?( <Divider />):""}

              {contact.telegram_username!=="" && contact.telegram_username?(  
                <ListItem button component="a" href={contact.telegram_username} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <TelegramIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.contactTitle} primary={"Telegram User:"} />
                  <ListItemText primary={contact.telegram_username.slice(20)} />
                </ListItem>
              ):""}
              {/* {contact.telegram_username!==""&&((contact.real_address!=="" && contact.real_address)|| (contact.extra_details!=="" && contact.extra_details))?( <Divider />):""} */}
              {contact.telegram_username!==""&&(contact.real_address ||contact.extra_details)?( <Divider />):""}

              {contact.real_address!=="" && contact.real_address?(
                <React.Fragment>
                    <ListItem button component="a" href={"https://www.google.com/maps/search/?api=1&query=" + contact.real_address} target="_blank" rel="noreferrer">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.contactTitle} primary={"Address:"} />
                    <ListItemText primary={contact.real_address} />
                    </ListItem>
                    <iframe title="Address Map" width='600' height='450' loading='lazy' allowFullScreen src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyCZCeqsUbU0To-0vARgVtwdKnTqNlN5G94&q=' + contact.real_address + '&zoom=13&center=' + contact.lat + "," + contact.lng}></iframe>
                    {/* <iframe title="Address Map" width='600' height='450' loading='lazy' allowFullScreen src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyCZCeqsUbU0To-0vARgVtwdKnTqNlN5G94&q=' + contact.real_address}></iframe> */}
                </React.Fragment>
              ):""}
              {contact.real_address!==""&&(contact.extra_details)?( <Divider />):""}

              {contact.extra_details!=="" && contact.extra_details?( 
                <ListItem button target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.contactTitle} primary={"Addional Details:"} />
                  <ListItemText primary={contact.extra_details} />
                </ListItem>
              ):""}
            </List>
              )}
        </Box>
    </Paper>
  );
}