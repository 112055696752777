export const configDict = {
        "crosUrl": "",
        "baseUrl": "https://www.listen2myapp.com/account/users.assets",
        "versionId": "v6.6",
        "appSetup": "app-setup.json",
        "design": "design.json",
        "server": "serverview.json",
        "about": "about.json",
        "links": "links.json",
        "social": "social.json",
        "contact": "contact.json",
        "rss": "rss.json",
        "gallery": "gallery.json",
        "web": "web.json"
      }

export const sectionsDeveloped = {
        "Song Request": true,
        "Social": true,
        "Links": true,
        "RSS": true,
        "Contact": true,
        "About": true,
        "Gallery": true,
        "Last Songs": false,
        "Liked Song": false,
        "Mixcloud": false,
        "Events": false,
        "Playlist": false,
        "Webcams": false,
        "Youtube Channel": false,
        "Chat": false,
        "Polls": false,
        "News": false,
        "Schedule": false,
        "Podcast": false,
        "Live Studio": false,
        "Alarm": false,
        "Youtube": false,
        "Soundcloud": false,
        "Rank App": false
      }