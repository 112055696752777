import React, {useReducer, createContext} from 'react'

export const PlayerContext = createContext();

const initialState = {
    server_id: 0,
    playing: false,
    volume: localStorage.getItem('myValueInLocalStorage_volume')!== null?localStorage.getItem('myValueInLocalStorage_volume'):0.5,
  };
  
  const reducer = (state, action) => {
    switch (action.type) {
        case "SWITCH_CHANNEL":
        return {
            volume: state.volume,
            playing: state.playing,
            server_id: action.payload
        };
      case "Play":
        return {
            volume: state.volume,
            playing: true,
            server_id: state.server_id
        };
      case "Pause":
        return {
            volume: state.volume,
            playing: false,
            server_id: state.server_id
        };
        case "VOLUME_CHANGE":
          return {
              volume: action.payload,
              playing: state.playing,
              server_id: state.server_id
          };
      default:
        throw new Error();
    }
  };

export const PlayerContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <PlayerContext.Provider value={[state, dispatch]}>
          {props.children}
        </PlayerContext.Provider>
      );
}
