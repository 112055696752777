import React, { useEffect, useContext, useRef, useState } from 'react';
import { ChannelContext } from '../context/channelContext'
import { PlayerContext } from '../context/playerContext'

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Tooltip } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    // padding: "8px 0 0 0"
  },
  playIcon: {
    height: 32,
    width: 32,
  },
  topButton: {
    height: 56,
    [theme.breakpoints.down("xs")]: {
      padding: '12px 0px',
    }
  },
  topPlayer: {
    display: 'none'
  }
}));


// function streamAddress(streamDetails){
//     // stream_type, server_link, streamid, mountpoint){
//     if(streamDetails.stream_type === "icecast2")
//         return streamDetails.server_link + "/" + streamDetails.mountpoint
//     else 
//         return streamDetails.server_link

// }

export default function TopPlayer(props) {
  const [stateChannel,] = useContext(ChannelContext);
  const [state, dispatch] = useContext(PlayerContext);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const audioRef = useRef();
  const classes = useStyles(props);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };


  const handleChooseChannel = (value) => {
    if (state.server_id !== value && stateChannel.radioID !== 0) {
      localStorage.setItem('myValueInLocalStorage_choosenId_' + stateChannel.radioID, value);
      dispatch({
        type: "SWITCH_CHANNEL",
        payload: value
      });
      dispatch({
        type: "Pause"
      });
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    setOpenAlert(false);
    audioRef.current.load();
    //**** Chrome blocks autoplay, show error "DOMException: play() failed because the user didn't interact with the document first" */
    // if(props.autoPlay){
    //   dispatch({
    //     type: "Play"
    //   });
    //   audioRef.current.play()
    // }
  }, [state.server_id, stateChannel.channels]);

  useEffect(() => {
    audioRef.current.volume = state.volume;
  }, [state.volume]);

  ///***** Get ERRROR FROM THE AUDIO PLAYER AND SHOW WARNING ALERT */
  function handleSourceError(e) { setOpenAlert(true) }
  function handleMediaError(e) {
    switch (e.target.error.code) {
      case e.target.error.MEDIA_ERR_ABORTED:
      case e.target.error.MEDIA_ERR_NETWORK:
      case e.target.error.MEDIA_ERR_DECODE:
      case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
      default:
        setOpenAlert(true);
    }
  }

  var toArray = Array.prototype.slice;
  toArray.apply(document.getElementsByTagName('audio')).forEach(function (audio) {
    audio.addEventListener('error', handleMediaError);
    toArray.apply(audio.getElementsByTagName('source')).forEach(function (source) {
      source.addEventListener('error', handleSourceError);
    });
  });



  let serverURL = ""
  if (stateChannel.channels[state.server_id]) {
    var res = stateChannel.channels[state.server_id].server_link.split(":");
    var ip = res[1].replace("//", "")
    var port = res[2]

    if (stateChannel.channels[state.server_id].stream_type === "shoutcast1") {
      serverURL = "https://proxy.listen2myapp.com/stream.php?type=s1&ip=" + ip + "&port=" + port
    }
    else if (stateChannel.channels[state.server_id].stream_type === "shoutcast2") {
      // serverURL = stateChannel.channels[state.server_id].server_link + "/" + stateChannel.channels[state.server_id].streamid + "/stream"
      serverURL = "https://proxy.listen2myapp.com/stream.php?type=s2&mount=/stream&ip=" + ip + "&port=" + port
    }
    else if (stateChannel.channels[state.server_id].stream_type === "live365") {
      serverURL = stateChannel.channels[state.server_id].server_link + "/" + stateChannel.channels[state.server_id].account_id
    }
    else if (stateChannel.channels[state.server_id].stream_type === "icecast2") {
      // serverURL = stateChannel.channels[state.server_id].server_link + "/" + stateChannel.channels[state.server_id].mountpoint
      serverURL = "https://proxy.listen2myapp.com/stream.php?type=ice&mount=stream&ip=" + ip + "&port=" + port
    }
    else {
      serverURL = stateChannel.channels[state.server_id].server_link
    }
  }

  // console.log(state.channels[0]?state.channels[state.server_id].server_link)
  return (
    <div className={classes.controls}>
      <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="warning">
          Our stream currently offline
        </Alert>
      </Snackbar>
      {
        <React.Fragment>
          <audio controls id="audio_player_channel" ref={audioRef} className={classes.topPlayer} >
            <source src={serverURL} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          {
            state.playing ? (
              <Tooltip title="Pause">
                <IconButton className={classes.topButton} color="inherit" aria-label="play/pause" onClick={() => {
                  dispatch({ type: "Pause" });
                  document.getElementById('audio_player_channel').pause()
                }}>
                  <PauseCircleOutlineIcon className={classes.playIcon} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Play">
                <IconButton className={classes.topButton} color="inherit" aria-label="play/pause" onClick={() => {
                  dispatch({ type: "Play" });
                  document.getElementById('audio_player_channel').volume = state.volume
                  document.getElementById('audio_player_channel').play()
                }}>
                  <PlayCircleOutlineIcon className={classes.playIcon} />
                </IconButton>
              </Tooltip>
            )
          }
          <Tooltip title="Switch Channel">
            <Button color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.topButton} >
              {/* {state.server_details.channel_name} */}
              {stateChannel.channels[state.server_id] ? stateChannel.channels[state.server_id].channel_name : ""}
            </Button>
          </Tooltip>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {stateChannel.channels[0] ?
              stateChannel.channels.map((elem, i) => {
                return <MenuItem key={i} onClick={() => { handleChooseChannel(elem.server_id - 1) }}>{elem.channel_name}</MenuItem>
              }) : <MenuItem >Loading..</MenuItem>
            }
          </Menu>
        </React.Fragment>
      }
    </div>
  );
}