import { Switch, Route, Redirect } from "react-router-dom";
import App from './App'


function MainPage() {
    return (
     <div>
         <Switch>
            <Route path="/:name" component={App} />
            <Route path="/">
                <Redirect to="/listen2myradioupdate" />
            </Route>
         </Switch>
     </div>
    );
  }

export default MainPage;