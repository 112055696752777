import React, { useEffect, useState, useContext } from 'react';
import { Switch, Route } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import GetMenuSetupFull from './components/Menu';
import Copyright from './components/Copyright'
import MediaPlayer from './sections/MediaPlayer'
import SongRequest from './sections/SongRequest'
import Social from './sections/Social'
import RSS from './sections/RSS'
import About from './sections/About'
import Contact from './sections/Contact'
import ContactForm from './sections/ContactForm'
import Links from './sections/Links'
import TopPlayer from './components/TopPlayer'
import Gallery from './sections/Gallery'
import LastSongs from './sections/LastSongs'
import { getData, getID } from './config/utils'

import { useParams } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import { ChannelContext } from './context/channelContext'
import { PlayerContext } from './context/playerContext'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: props => props.topBarColor,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down("xs")]: {
      marginRight: 6,
    }
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      lineHeight: 'normal',
    }
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '100%',
  },
  topButton: {
    [theme.breakpoints.down("xs")]: {
      padding: '12px 0px'
    }
  }

}));

function App() {
  const [stateChannel, dispatchChannel] = useContext(ChannelContext);
  const [statePlayer, dispatchPlayer] = useContext(PlayerContext);

  const [open, setOpen] = useState(window.innerWidth > 600 ? true : false);
  const [radioName, setRadioName] = useState("Loading...");
  const [autoPlay, setAutoPlay] = useState(false);
  const [topBarColor, setTopBarColor] = useState("#000000")
  const [androidURL, setAndroidURL] = useState("");
  const [iOSURL, setiOSURL] = useState("");
  const props = { topBarColor: topBarColor }
  const classes = useStyles(props);
  let { name } = useParams();

  useEffect(() => {
    getID(name).then(res => {
      if (res === 0) {
        window.location.replace("https://www.listen2myapp.com/");
      }
      else {
        dispatchChannel({ type: "Set_RadioId", payload: res });
        // console.log(localStorage.getItem('myValueInLocalStorage_choosenId_'+ res))
        if (localStorage.getItem('myValueInLocalStorage_choosenId_' + res) !== null) {
          dispatchPlayer({ type: "SWITCH_CHANNEL", payload: Number(localStorage.getItem('myValueInLocalStorage_choosenId_' + res)) });
        }
      }
    })
  }, [])

  useEffect(() => {
    if (stateChannel.radioID !== null) {
      getData("server", stateChannel.radioID).then(res => {
        if (!stateChannel.channels[0])
          dispatchChannel({
            type: "Channels",
            payload: res
          });
      })
      getData("appSetup", stateChannel.radioID).then(res => {
        try {
          setRadioName(res.app_name)
          setAutoPlay(res.auto_play === "Show" ? true : false)
          dispatchChannel({ type: "Set_RadioName", payload: res.app_name });
          // setRadioIcon(res.app_icon)
        } catch (err) {
          console.log(err)
        }
      });

      getData("design", stateChannel.radioID).then(res => {
        setTopBarColor(res.top_bar_color)
      })

      getData("web", stateChannel.radioID).then(res => {
        setAndroidURL(res.android_store_link)
        setiOSURL(res.iphone_store_link)
      })
    }
  }, [, stateChannel.radioID])

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  // console.log('id:' + props.match.params.id)

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{radioName}</title>
      </Helmet>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" className={classes.title}>
            {radioName}
          </Typography>
          {androidURL !== "" &&
            <Tooltip title="Android App">
              <IconButton color="inherit" href={androidURL} target="_blank" rel="noopener noreferrer" className={classes.topButton}>
                <Badge color="secondary">
                  <PhoneAndroidIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          }
          {iOSURL !== "" &&
            <Tooltip title="iPhone App">
              <IconButton color="inherit" href={iOSURL} target="_blank" rel="noopener noreferrer" className={classes.topButton}>
                <Badge color="secondary">
                  <PhoneIphoneIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          }
          <TopPlayer autoPlay={autoPlay} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <GetMenuSetupFull name={name} radioId={stateChannel.radioID} />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route path="/:name/MediaPlayer" component={MediaPlayer} />
            <Route path="/:name/SongRequest" component={SongRequest} />
            <Route path="/:name/Social" component={Social} />
            <Route path="/:name/RSS/:rss_id" component={RSS} />
            <Route path="/:name/RSS/" component={RSS} />
            <Route path="/:name/About" component={About} />
            <Route path="/:name/Contact" component={Contact} />
            <Route path="/:name/ContactForm" component={ContactForm} />
            <Route path="/:name/Links" component={Links} />
            <Route path="/:name/Gallery/:gallery_id" component={Gallery} />
            <Route path="/:name/Gallery" component={Gallery} />
            <Route path="/:name/LastSongs" component={LastSongs} />
            <Route path="/:name/" component={MediaPlayer} />
          </Switch>
          <Box pt={4}>
            <Copyright stationName={radioName} />
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default App;
