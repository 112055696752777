import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ChannelContextProvider} from './context/channelContext'
import { PlayerContextProvider} from './context/playerContext'

import './index.css';

// import App from './App';
// import reportWebVitals from './reportWebVitals';
import MainPage from './mainPage';

ReactDOM.render(
  <ChannelContextProvider>
    <PlayerContextProvider>
      <BrowserRouter>
        <MainPage />
      </BrowserRouter>
    </PlayerContextProvider>
  </ChannelContextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
