
import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia } from '@material-ui/core';
import { getData } from '../config/utils'
import { ChannelContext } from '../context/channelContext'

const useStyles = makeStyles((theme) => ({
    cover: {
        width: 155,
        height: "100%",
        margin: "0 0 0 20px"
      }
  }));

export default function Thumbnail() {
    const [stateChannel,] = useContext(ChannelContext);
    const [img,setImg] = useState("")
    const classes = useStyles();

    getData("web",stateChannel.radioID).then((res)=>{
        try{ 
            setImg(res.app_icon)
        } catch (err) {
            // console.log(err)
        }
    })
    
return (
    <CardMedia
        className={classes.cover}
        image={img}
        title="Album Cover"
    />
)}