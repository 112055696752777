import React,{ useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { Box, Paper, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import {getData} from '../config/utils'
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from '@material-ui/lab/Skeleton';
import { ChannelContext } from '../context/channelContext'
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    margin: '0 0 20px 0'
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
});

export default function RSS(props) {
  const [stateChannel,] = useContext(ChannelContext);
  const [RSS, setRSS] = React.useState([])
  const [RSSresult, setRssResult] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [loadingList, setLoadingList] = React.useState(true)
  const classes = useStyles();

  let rss_id = props.match.params.rss_id;

  useEffect(() => {
      if(stateChannel.radioID>0)
      getData("rss",stateChannel.radioID).then(res => {
        setRSS(res)
        setLoadingList(false)
          if(rss_id>0 && res[rss_id-1]){
              axios.get('https://api.rss2json.com/v1/api.json?rss_url=' + res[rss_id-1].rss_url)
              .then(res => {
                setRssResult(res.data.items)
                setLoading(false)
              })
          }
      })
    },[stateChannel.radioID,rss_id])

  return (
    <Paper>
      <Helmet>
        <title>{stateChannel.radioName} - RSS</title>
        <meta name="description" content="RSS section" />
      </Helmet>      
      {rss_id>=0?(
          <Box p={3} bgcolor="background.paper">
             <List component="nav" aria-label="mailbox folders">
            {loading?
              (
                <div>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </div>
              ):(
                RSSresult.map((elem,i) => (
                <Grid item key={i}>
                <CardActionArea component="a" href={elem.link + ''} target="_blank" rel="noreferrer">
                  <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                      <CardContent>
                        <Typography component="h2" variant="h5">
                          {elem.title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {elem.pubDate}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                          {elem.description}
                        </Typography>
                        <Typography variant="subtitle1" color="primary">
                          Continue reading...
                        </Typography>
                      </CardContent>
                    </div>
                    <Hidden xsDown>
                      <CardMedia className={classes.cardMedia} image={elem.thumbnail} title={elem.title} />
                    </Hidden>
                  </Card>
                </CardActionArea>
              </Grid>
              )))
            }
             </List>
          </Box>
      ):(
        <Box p={3} bgcolor="background.paper">
            <List component="nav" aria-label="mailbox folders">
            {loadingList?
              (
                <div>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </div>
              ):(
                RSS.map((elem,i)=>(
                    <React.Fragment key={i}>
                        <ListItem button component={Link} to={"RSS/" + elem.rss_id}>
                            <ListItemText primary={elem.rss_name} />
                        </ListItem>
                        {RSS.length !== i+1 ?<Divider />:""}
                    </React.Fragment>
                ))
              )}
            </List>
        </Box>
        )}
    </Paper>
  );
}

