
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import ShareIcon from '@material-ui/icons/Share';
import EventIcon from '@material-ui/icons/Event';
import HeadsetIcon from '@material-ui/icons/Headset';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import LanguageIcon from '@material-ui/icons/Language';
import EmailIcon from '@material-ui/icons/Email';
import InfoIcon from '@material-ui/icons/Info';
import ChatIcon from '@material-ui/icons/Chat';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import PollIcon from '@material-ui/icons/Poll';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LinkedCameraIcon from '@material-ui/icons/LinkedCamera';
import CloudIcon from '@material-ui/icons/Cloud';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import MusicVideoIcon from '@material-ui/icons/MusicVideo';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WbSunnyIcon from '@material-ui/icons/WbSunny';

// Icons Dictionary
export const iconsDict = {
    "Media Player": (<PlayCircleFilledWhiteIcon />),
    "Recent Songs": (<HeadsetIcon />),
    "News": (<ChatIcon />),
    "Gallery": (<PhotoLibraryIcon />),
    "Social": (<ShareIcon />),
    "Contact": (<EmailIcon />),
    "Links": (<LanguageIcon />),
    "About": (<InfoIcon />),
    "RSS": (<RssFeedIcon />),
    "Last Songs": (<LibraryMusicIcon />),
    "Polls": (<PollIcon />),
    "Chat": (<ChatIcon />),
    "Alarm": (<WatchLaterIcon />),
    "Favorite Songs": (<FavoriteIcon />),
    "Liked Song": (<FavoriteIcon />),
    "Song Request": (<LibraryMusicIcon />),
    "Events": (<EventIcon />),
    "Webcams": (<LinkedCameraIcon />),
    "Mixcloud": (<CloudIcon />),
    "Playlist": (<QueueMusicIcon />),
    "Podcast": (<MusicVideoIcon />),
    "Podcasts": (<MusicVideoIcon />),
    "SoundCloud": (<CloudIcon />),
    "Rank App": (<ThumbUpIcon />),
    "Schedule": (<EventIcon />),
    "Youtube Channel": (<YouTubeIcon />),
    "Youtube": (<YouTubeIcon />),
    "Live Studio": (<YouTubeIcon />),
    "Weather": (<WbSunnyIcon />)
  }