import React, { useContext } from 'react';
import { ChannelContext } from '../context/channelContext'
import { PlayerContext } from '../context/playerContext'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default function Channels(props) {
  const [stateChannel, ] = useContext(ChannelContext);
  const [statePlayer, dispatchPlayer] = useContext(PlayerContext);
  // console.log(stateChannel.channels)

  const handleChange = (event, newValue) => {
    localStorage.setItem('myValueInLocalStorage_choosenId_'+ stateChannel.radioID, newValue);

    dispatchPlayer({
      type: "SWITCH_CHANNEL",
      payload: newValue
    });
    dispatchPlayer({
      type: "Pause"
    });
  };

  return (
        <AppBar position="static" color="inherit" elevation={0}>
          <Tabs 
            value={stateChannel.channels[statePlayer.server_id]?statePlayer.server_id:0}
            onChange={handleChange} 
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs" className="tabs">
            {stateChannel.channels[0]?stateChannel.channels.map((elem,i) => (
              <Tab key={i} label={elem.channel_name} value={elem.server_id-1}/>
            )):<Tab label='Loading...' />}
          </Tabs>
        </AppBar>
  );
}