import React, {useReducer, createContext} from 'react'

export const ChannelContext = createContext();

const initialState = {
    radioID: null,
    channels: {},
    radioName: ""
  };
  
  const reducer = (state, action) => {
    switch (action.type) {
      case "Channels":
        return {
          radioID:  state.radioID,
          radioName: state.radioName,
          channels: action.payload
        };
      case "Set_RadioId":
          return {
            radioID:  action.payload,
            radioName: state.radioName,
            channels: state.channels
          };
      case "Set_RadioName":
        return {
          radioID:  state.radioID,
          radioName: action.payload,
          channels: state.channels
        };        
      default:
        throw new Error();
    }
  };

export const ChannelContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ChannelContext.Provider value={[state, dispatch]}>
          {props.children}
        </ChannelContext.Provider>
      );
}
