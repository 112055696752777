import React, {useContext} from 'react';
import { ChannelContext } from '../context/channelContext'
import { PlayerContext } from '../context/playerContext'

export default function Banner(props) {
  // Subscribe to `channel_id` state and access dispatch function
  const [stateChannel, ] = useContext(ChannelContext);
  const [statePlayer, ] = useContext(PlayerContext);

  let img = props.deafultBanner;
  if(stateChannel.channels[statePlayer.server_id]){
    if(stateChannel.channels[statePlayer.server_id].bg_image !== "")
      img = stateChannel.channels[statePlayer.server_id].bg_image;
  }
  
  return (
      <img src={img} height="auto" max-width="50%" alt=""/>
  );
}