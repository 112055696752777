import React, {useEffect, useContext} from 'react';
import { getData } from '../config/utils'
import { ChannelContext } from '../context/channelContext'

import Banner from '../components/Banner';
import Channels from '../components/Channels';
import Player from '../components/Player';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
      },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: '100%',
    },
  }));
  

export default function MediaPlayer(props) {
  const [stateChannel, ] = useContext(ChannelContext);

  const [radioBanner, setRadioBanner] = React.useState("")

  const classes = useStyles(props);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    if(stateChannel.radioID !== null) {
      getData("design",stateChannel.radioID).then(res => {
          setRadioBanner(res.logobanner)
        })
    }
  },[stateChannel.radioID])

  return (
        <Grid container spacing={3}>
            <Helmet>
                <title>{stateChannel.radioName}</title>
                <meta name="description" content="Listen To Our Station" />
            </Helmet>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                <Channels channels={stateChannel.channels} radioID={stateChannel.radioID} />
                </Paper>
            </Grid>
            <Hidden smDown>
              <Grid item xs={10} md={6} lg={6}>
                <Paper className={fixedHeightPaper}>
                    <Banner deafultBanner = {radioBanner} />
                </Paper>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Paper className={fixedHeightPaper}>
                <Player />
                {/* <Icon imgUrl = {radioIcon}/> */}
                </Paper>
            </Grid>
            <Hidden mdUp>
              <Grid item xs={12} md={6} lg={6}>
                <Paper className={fixedHeightPaper}>
                    <Banner deafultBanner = {radioBanner} />
                </Paper>
              </Grid>
            </Hidden>
        </Grid>
  );
}