import { Box, Paper, Divider, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import React,{useEffect, useContext} from 'react';
import {getData} from '../config/utils';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import Skeleton from '@material-ui/lab/Skeleton';
import { ChannelContext } from '../context/channelContext'
import { Helmet } from 'react-helmet';

export default function Social() {
    const [stateChannel,] = useContext(ChannelContext);
    const [social, setSocial] = React.useState("")
    const [loading, setLoading] = React.useState(true)
        
    useEffect(() => {
      if(stateChannel.radioID>0)
      getData("social",stateChannel.radioID).then(res => {
        setSocial(res)
        setLoading(false)
          })
    },[stateChannel.radioID])
    
  return (
    <Paper>
      <Helmet>
        <title>{stateChannel.radioName} - Social</title>
        <meta name="description" content="Social section" />
      </Helmet>      
        <Box p={3} bgcolor="background.paper">
        {loading?
              (
                <div>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
              ) : (
            <List component="nav" aria-label="mailbox folders">
              {social.facebook!==""?(
                <ListItem button component="a" href={social.facebook} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <FacebookIcon />
                  </ListItemIcon>
                  <ListItemText primary="Facebook" />
                </ListItem>
              ):""}
              {social.facebook!==""&&(social.twitter!==""||social.youtube!==""||social.instagram!=="")?( <Divider />):""}
              {social.twitter!==""?(
                <ListItem button component="a" href={social.twitter} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <TwitterIcon />
                  </ListItemIcon>
                  <ListItemText primary="Twitter" />
                </ListItem>
              ):""}
              {social.twitter!==""&&(social.youtube!==""||social.instagram!=="")?( <Divider />):""}
              {social.youtube!==""?(  
                <ListItem button component="a" href={social.youtube} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <YouTubeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Youtube" />
                </ListItem>
              ):""}
              {social.youtube!==""&&social.instagram!==""?( <Divider />):""}
              {social.instagram!==""?(  
                <ListItem button component="a" href={social.instagram} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <InstagramIcon />
                  </ListItemIcon>
                  <ListItemText primary="Instagram" />
                </ListItem>
              ):""}
            </List>
              )}
        </Box>
    </Paper>
  );
}