import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import Slider from '@material-ui/core/Slider';
import PlayerTitle from './SongPlayed';
import Thumbnail from './Thumbnail'
import { PlayerContext } from '../context/playerContext'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 48,
    width: 48,
  },
  slider: {
    width: 80,
  },
  VolumeUp: {
    margin: "0 20px 0 0"
  }
}));

// function serverUrl(server_details){
//   let streamingURL = "";
//   let stream_type = server_details.stream_type;
//   let channel_ip = server_details.channel_ip;
//   let mountpoint = server_details.mountpoint;
//   let channel_port = server_details.channel_port;
//   let streamid = server_details.streamid;
//   let account_id = server_details.account_id;
//   let user_id = server_details.user_id;

//   switch (stream_type) {
//     case "podcast":
//         streamingURL = "https://www.listen2myapp.com/account/users.assets/" + user_id + "/podcast_channel/" + channel_ip; //server link build
//         break;
//     case "other":
//         streamingURL = channel_ip; //server link build
//         break;
//     case "radiojar":
//         streamingURL = channel_ip; //server link build
//         break;

//     case "samcloud":
//         streamingURL = channel_ip; //server link build
//         break;
//     case "rsas":
//         streamingURL = channel_ip + "/" + mountpoint; //server link build
//         break;
//     case "shoutcast1":
//         streamingURL = channel_ip + ":" + channel_port + "/;stream.mp3"; //server link build
//         break;

//     case "shoutcast2":
//         streamingURL = channel_ip + ":" + channel_port + "/stream"; //server link build
//         break;

//     case "icecast2":
//         streamingURL = channel_ip + ":" + channel_port + "/" + mountpoint; //server link build
//         break;

//     case "radionomy":
//         streamingURL = channel_ip; //server link build
//         break;

//     case "mp3":
//         streamingURL = channel_ip; //server link build
//         break;

//     case "live365":
//         streamingURL = channel_ip + "/" + account_id; //server link build
//         break;

//     case "radio.co":
//         streamingURL = channel_ip; //server link build
//         break;

//     case "radioking":
//         streamingURL = channel_ip + "/" + mountpoint; //server link build
//         break;

//     case "video":
//         streamingURL = channel_ip; //server link build
//         break;
//   }
//   return streamingURL;
// }


export default function Player() {
  const [statePlayer, dispatchPlayer] = useContext(PlayerContext);
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <PlayerTitle />
        <div className={classes.controls}>
          {
            statePlayer.playing?(
              <IconButton aria-label="play/pause" onClick={()=>{
                dispatchPlayer({ type: "Pause" });
                document.getElementById('audio_player_channel').pause()
                }}> 
                <PauseIcon className={classes.playIcon} />
              </IconButton>
            ): (
              <IconButton aria-label="play/pause" onClick={()=>{
                dispatchPlayer({ type: "Play" });
                document.getElementById('audio_player_channel').play()
                }}> 
                <PlayArrowIcon className={classes.playIcon} />
              </IconButton>
            )
          }
              <VolumeDown />
              <Slider aria-labelledby="continuous-slider" 
                className={classes.slider}
                min={0.0}
                max={1.0}
                step={0.05}
                value={statePlayer.volume}
                onChange={(event, newValue)=>{
                  document.getElementById('audio_player_channel').volume = newValue;
                  dispatchPlayer({ type: "VOLUME_CHANGE", payload: newValue});
                  localStorage.setItem('myValueInLocalStorage_volume', newValue);
                }} 
              />
              <VolumeUp className={classes.VolumeUp} />
        </div>
      </div>
        <Thumbnail />
    </Card>
  );
}