import React, { useContext, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ChannelContext } from '../context/channelContext'
import { PlayerContext } from '../context/playerContext'

import { Helmet } from 'react-helmet';

const axios = require('axios');


const validationSchema = yup.object({
    name: yup
    .string('Enter your name')
    .required('Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    artist: yup
      .string('Enter desired artist name')
      .required('Artist is required'),
    song: yup
      .string('Enter desired song name')
      .required('Song is required'),
  });

export default function SongRequest(props) {
    const [stateChannel,] = useContext(ChannelContext);
    const [statePlayer,] = useContext(PlayerContext);

    const [successfullySent, setSuccessfullySent] = useState(false)
    const [errorDuringSend, setErrorDuringSend] = useState(false)

    var optionAxios = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    }
      const formik = useFormik({
        initialValues: {
          name: '',
          email: '',
          artist: '',
          song: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

          var bodyFormData = new FormData();
            bodyFormData.append('name', values.name);
            bodyFormData.append('listener_email', values.email);
            bodyFormData.append('song_artist', values.artist);
            bodyFormData.append('song_name', values.song);
            bodyFormData.append('user_id', stateChannel.radioID);
            bodyFormData.append('channel_name', stateChannel.channels[statePlayer.server_id].channel_name);

            axios.post('https://www.listen2myapp.com/account/process_request.php', bodyFormData ,optionAxios)
              .then(function (response) {
                // console.log(response.data);
                // console.log(response.status);
                // console.log(response.statusText);
                // console.log(response.headers);
                // console.log(response.config);
                if(response.statusText === 'OK') setSuccessfullySent(true)
                else setErrorDuringSend(true)
              })
              .catch(function (error) {
                console.log(error);
              });
        },
      });

      
    
  return (
    <Paper>
      <Helmet>
        <title>{stateChannel.radioName} - Song Request</title>
        <meta name="description" content="Song request section" />
      </Helmet>      
        <Box p={3} bgcolor="background.paper">
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    />
                    <br/><br/>
                    <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    />
                    <br/><br/>
                    <TextField
                    fullWidth
                    id="artist"
                    name="artist"
                    label="Artist Name"
                    value={formik.values.artist}
                    onChange={formik.handleChange}
                    error={formik.touched.artist && Boolean(formik.errors.artist)}
                    helperText={formik.touched.artist && formik.errors.artist}
                    />
                    <br/><br/>
                    <TextField
                    fullWidth
                    id="song"
                    name="song"
                    label="Song Name"
                    value={formik.values.song}
                    onChange={formik.handleChange}
                    error={formik.touched.song && Boolean(formik.errors.song)}
                    helperText={formik.touched.song && formik.errors.song}
                    />
                    <br/><br/>
                    {!successfullySent && 
                        <Button variant="outlined" type="submit" color="primary">
                            Send
                        </Button>
                    }
                    {successfullySent && <h3>Thanks, the song request has been sent</h3>}
                    {errorDuringSend && <h3>This song request can't be sent right now. Please try again later</h3>}
                </form>
            </div>
        </Box>
    </Paper>
  );
}