import React, { useEffect, useContext } from 'react';
import { getData } from '../config/utils'
import { Box, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ChannelContext } from '../context/channelContext'
import { Helmet } from 'react-helmet';

export default function About(props) {
  const [stateChannel,] = useContext(ChannelContext);
  const [about, setAbout] = React.useState("")
  const [loading, setLoading] = React.useState(true)


  useEffect(() => {
    if (stateChannel.radioID > 0)
      getData("about", stateChannel.radioID).then(res => {
        setAbout(res.page_descrp)
        setLoading(false)
      })
  }, [stateChannel.radioID])

  return (
    <Paper>
      <Helmet>
        <title>{stateChannel.radioName} - About</title>
        <meta name="description" content="About section" />
      </Helmet>
      <Box p={3} bgcolor="background.paper">
        {loading ?
          (
            <div>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: about }} />
          )}
      </Box>
    </Paper>
  );
}